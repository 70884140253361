body {
    font-family: Arial, Helvetica, sans-serif, Hack;
    margin: 0;
    background: #eee;
    height: auto;
    overflow: auto;
}

h1, h2, h3, p {
    color: black
}

.noGap {
    margin: 0;
}

main {
    max-width: 768px;
    margin: auto;
    box-shadow: 30px 0px 40px rgba(0, 0, 0, 1),
    -30px 0px 40px rgba(0, 0, 0, 1);
}

header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 999;
}
.form-group {
    margin: 20px 0;
  }
  .form-control {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  .country-select {
    margin-bottom: 20px;
  }
.wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f7f7;
    font-family: Arial, sans-serif;
    color: #333;
}

.wrapper main {
    margin-top: 40px;
    margin-bottom: 40px;
}

#landing {
    align-items: center;
}

#landing-img {
    background: url(../landing-img.jpg);
    background-position: center;
    background-size: cover;
    height: 100vh;
    overflow-y: scroll;
}

.bg-image {
    background-image: url(../landing-img.jpg);
}

/* Index */

.container-1 {
    height: 60vh;
    width: auto;
}


img {
    display: block;
    width: 100%;
}

.img-responsive {
    max-width: 120px;
}

.logotext-img {
    padding: 20px 10px;
    margin: 20px auto;
    display: block;
    max-width: 100%;
}

header-image {
    position: relative;
    height: 20vh;
    background-color: transparent;

}

header-image img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.container-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20vh;
    width: auto;
}

#index-con-2 {
    margin-top: -20px;
}

.container-3 {
    display: flex;
    justify-content: center;
    height: 30vh;
    width: auto;
}

.container-4 {
    display: flex;
    justify-content: center;
    height: 60vh;
    width: auto;
}

.container-5 {
    display: flex;
    justify-content: center;
    height: 40vh;
    width: auto;
}

.container-6 {
    height: 50vh;
    width: auto;
}

#col {
    margin-left: 15px;
    margin-right: 15px;
    padding: 5px;
}

.img-thumb {
    width: auto;
    max-width: 70px;
}

.icon {
    height: 100%;
    width: auto;
    padding: 5px;
}

/* Møten */

.text-mote {
    width: 60vw;
}


.container-4-h1 {
    margin: 0;
}

.text-mote {
    color: royalblue;
}

.form-text {
    color: royalblue;
}

#mote-con-1 {
    margin-top: -25px;
}

#mote-con-2 {
    margin-top: -10px;
}

/* maps linken */
.maps-link {
    width: 30vw;
}

/* left arrow */


#back {
    height: 40px;
    width: auto;
}

#left-arrow {
    position: fixed;
    bottom: 5px;
}

.left-arrow {
    display: block;
    margin: auto;
    height: 10vh;
    width: 50%;
}

#text-reg {
    text-align: center;
}

.btn-box-1 {
    display: flex;
}

.btn-dark {
    color: royalblue
}

.box-pam-1 {
    width: 100vw;
}

.box-pam-2 {
    width: 100vw;
}

/* Pamfletter Main */

.pam {
    display: flex;
    height: 100%;
    min-height: 100vh;
    flex: 0 1 100vw;
}

.box-pam {
    display: flex;
    flex-flow: column;
    border-style: solid;
    border-width: 3px;
    border-color: black;
    margin: 10px;
    padding: 10px;
}

.box-pam h1, h2 {
    text-align: center;
}

.box-pam p {
    text-align: start;
    color: black;
}

/* Hvem */

#pam-hvem {
    background-color: lightblue;
}

/* Hva */

#pam-hva {
    background-color: lightyellow;
}

/* Hvorfor */

#pam-hvorfor {
    background-color: burlywood;
}

/* Hvordan*/

#pam-hvordan {
    background-color: whitesmoke;
}

/* Tradisjoner */

#pam-trad {
    background-color: lightgray;
}

/* Bare For Idag */

#pam-bare {
    background-color: violet;
}

/* Vi Tilfrisknar */

#pam-vitil {
    background-color: lightgreen;
}

.bakken {
    display: flex;
    flex-wrap: nowrap;
}

/* in your CSS file */
.bakken-test {
    width: 100vw; /* full viewport width */
    height: 100vh; /* full viewport height */
    overflow: auto; /* allow scrolling */
    pointer-events: auto; /* allow clicking and zooming */
}

.bakken-test img {
    display: block;
    max-width: 100%; /* scale image to fit container */
    max-height: 100%; /* scale image to fit container */
}

.box-1 {
    display: flex;
    justify-content: center;
    width: 100%;

}

.kontakt-text {
    display: flex;
    flex-flow: column;
    align-content: center;
    justify-content: center;
}

.kontakt-text h3, p {
    color: royalblue;
    text-align: center;
}

.kon-box-2 {
    margin-top: -20px;
}

.registration-page .amplify-label {
    color: royalblue;
}

